<template>
  <v-container fluid grid-list-xs class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            padding-top: 10;
            font-weight: bold;
            font-size: 5vw;
          "
          class="grey lighten-5"
        >
          <v-flex xs5 class="text-left ml-0 pl-0 mb-2 mt-1">
            <v-btn icon @click="OnBackToReport()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
          </v-flex>

          <v-flex xs7 class="text-left ml-4 mt-2 pink--text text--darken-1">
            บัญชี</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold; height: 100px"
          class="mt-0 mb-0 py-0 pink darken-3"
        >
          <v-flex
            xs12
            class="text-center grey--text text--lighten-3"
            style="margin: auto"
          >
            <h4>สรุปยอดคงเหลือ (ตัวอย่าง ยังไม่ใช่ข้อมูลจริง)</h4>
            <h2>- 10,000 บาท</h2>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="mt-0 mb-0 py-0"
        >
          <v-flex xs12 class="text-left">
            <v-list lines="two">
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >สรุปงวด (เจ้าจ่าย)</v-list-item-title
                  >
                  <v-list-item-subtitle>1 เม.ย. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >คงเหลือ + 120,000
                  <div  class="grey--text text--darken-2">จาก + 120,000</div></v-list-item-action
                >
              </v-list-item>
            </v-list>

            <v-list lines="two" class="mt-5">
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="red"> mdi-chevron-double-down </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text text--darken-2"
                    >ยืม</v-list-item-title
                  >
                  <v-list-item-subtitle>17 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="red--text text--darken-2"
                  >คงเหลือ - 130,000
                  <div class="grey--text text--darken-2">จาก - 200,000</div></v-list-item-action
                >
              </v-list-item>
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >ชำระ</v-list-item-title
                  >
                  <v-list-item-subtitle>20 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >+20,000</v-list-item-action
                >
              </v-list-item>
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >ชำระ</v-list-item-title
                  >
                  <v-list-item-subtitle>29 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >+50,000</v-list-item-action
                >
              </v-list-item>
            </v-list>


            
            <v-list lines="two" class="mt-5">
              <!-- <v-list-item
                v-for="n in 3"
                :key="n"
                style="border-bottom: 1px LightPink solid"
              >
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >สรุปงวด 1 ก.พ. 66</v-list-item-title
                  >
                  <v-list-item-subtitle>1 ก.พ. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >+1,000</v-list-item-action
                >
              </v-list-item> -->
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="red"> mdi-chevron-double-down </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text text--darken-2"
                    >สรุปงวด (เก็บส่งเจ้า)</v-list-item-title
                  >
                  <v-list-item-subtitle>16 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="red--text text--darken-2"
                  >จบรายการ
                  <div  class="grey--text text--darken-2">จาก -2,1000</div></v-list-item-action
                >
              </v-list-item>
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >ชำระค่างวด</v-list-item-title
                  >
                  <v-list-item-subtitle>17 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >+6,000</v-list-item-action
                >
              </v-list-item>
              <v-list-item style="border-bottom: 1px LightPink solid">
                <v-list-item-icon>
                  <v-icon color="green"> mdi-chevron-double-up </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="green--text text--darken-2"
                    >ชำระค่างวด</v-list-item-title
                  >
                  <v-list-item-subtitle>20 มี.ค. 66</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="green--text text--darken-2"
                  >+15,000</v-list-item-action
                >
              </v-list-item>
  

            </v-list>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="mt-3 mb-1 py-0"
        >
          <v-flex xs12 class="text-left">
            <v-chip color="green darken-4" outlined style="height: 50px">
              <span class="text-h6">สรุปยอดทั้งหมด + 10,000 บาท</span>
              
            </v-chip>
          </v-flex>
        </v-layout>
      </v-flex> -->

      <!-- <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="mt-3 mb-1 py-0"
        >
          <v-flex xs12 class="text-right">
            <v-chip color="green darken-4" outlined style="height: 50px">
              <span class="text-h6">สรุปยอดทั้งหมด + 10,000 บาท</span>
            </v-chip>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

// import ReportRow from "@/components/Report/CoverPage/Row";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    //component name,
    // ReportRow,
  },
  computed: {
    // // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // PriceSum() {
    //   return parseInt(this.$store.getters["page/PageData"].PriceSum);
    // },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    //   this.PageNumber = this.$route.query.PageNumber;
    //   this.CusID = this.$route.query.CusID;
    //   // this.updateDataTableHeight();
    //   setTimeout(() => {
    //     this.updateDataTableHeight();
    //   }, 500);
    this.GetData();
  },
  methods: {
    async GetData() {
      let parameter = {
        to: "Report",
        method: "prize",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.FirstPrize = response.REPORT_PRIZE.FirstPrize;
          this.LastTwo = response.REPORT_PRIZE.LastTwo;
          this.FourTime1 = response.REPORT_PRIZE.FourTime1;
          this.FourTime2 = response.REPORT_PRIZE.FourTime2;
          this.FourTime3 = response.REPORT_PRIZE.FourTime3;
          this.FourTime4 = response.REPORT_PRIZE.FourTime4;
        },
        (error) => {}
      );
    },

    OnBackToReport() {
      this.$router.push({
        path: "Reports",
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    FirstPrize: "",
    LastTwo: "",
    FourTime1: "",
    FourTime2: "",
    FourTime3: "",
    FourTime4: "",

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,
  }),
};
</script>

<style scoped>
.container {
  background: linear-gradient(
    135deg,
    #d0faee,
    #dcf3e5,
    #e7ecdc,
    #f3e5d3,
    #ffdeca
  );
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
  z-index: 9999;
}

.border {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.border-bottom {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
.border-right {
  border-right: 1px solid black;
}
</style>
